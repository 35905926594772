import { Fold, ArrowDown, Expand } from '@element-plus/icons-vue';
export default {
  components: {
    Fold,
    Expand,
    ArrowDown
  },
  props: {
    menuList: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['get-collapse'],
  data() {
    return {
      show: true,
      foldDisabled: false,
      expandDisabled: false
    };
  },
  methods: {
    openCollapse(type) {
      this.isCollapse = !this.isCollapse;
      this.$emit('get-collapse', this.isCollapse);
      if (type === 'fold') {
        this.foldDisabled = !this.foldDisabled;
      } else {
        this.expandDisabled = !this.expandDisabled;
      }
      this.show = !this.show;
    },
    async handleCommand() {
      try {
        await this.$store.dispatch('logout');
        localStorage.removeItem('logistics-token');
        window.location.href = '/';
      } catch (err) {
        return;
      }
    }
  }
};